<template>
  <div class="mt-3">
    <router-link :to="{ name: 'SingleSignOn' }">
      <SingleSignOnLogo/>
    </router-link>
  </div>
</template>

<script>
import SingleSignOnLogo from '@/components/App/UI/SingleSignOnLogo.vue';

export default {
  components: {
    SingleSignOnLogo,
  },
  methods: {},
};
</script>
