<template>
  <card class="p-3 col-lg-8 bg-secondary">
    <h1 class="page-header mb-lg-3 card-title">Sign in to your account</h1>
    <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
      <form class="needs-validation" @submit.prevent="validate().then(submit)">
        <div class="row d-flex justify-content-center">
          <div class="mx-3">
            <b-row>
              <b-col lg="12">
                <base-input
                  v-model="email"
                  alternative
                  group
                  name="email"
                  placeholder="Enter your email"
                  rules="required|email"
                  type="email"
                  formGroupClasses="mb-1"
                ></base-input>
              </b-col>
              <b-col lg="12">
                <base-input
                  ref="password"
                  v-model="password"
                  :type="show_password ? 'text' : 'password'"
                  alternative
                  autocomplete="off"
                  group
                  name="password"
                  placeholder="Enter password"
                  rules="required"
                  formGroupClasses="mb-1"
                >
                  <template slot="append">
                    <i :class="show_password === true ? 'fas fa-eye-slash' : 'fas fa-eye'" aria-hidden="true"
                       class="eye-icon" @click="show_password = !show_password"></i>
                  </template>
                </base-input>
              </b-col>
            </b-row>
            <div class="layout-button">
              <div class="form-common-errors small-text">
                {{ loginFormErrors }}
              </div>
              <button
                :disabled="invalid"
                class="btn btn-outline btn-primary btn-block font-weight-bold"
                type="submit"
              >Sign in
              </button>
            </div>
            <div class="d-flex align-items-center flex-column mt-4">
              <p class="mb-0 font-weight-500">Or continue with:</p>
              <google-button/>
              <microsoft-button/>
              <single-sign-on-button/>
            </div>
            <div class="mt-2rem text-center">
              <!-- <p class="font-weight-bold">
                Don’t have an account?
                <router-link :to="{ name: 'Register' }">Sign up</router-link>
              </p> -->
              <p>Forgot your password?
                <router-link :to="{ name: 'ResetPassword' }">Reset password</router-link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>

<script>
import {mapActions} from 'vuex';
import {LOGIN_REQUEST} from '/src/store/storeActions';
import MicrosoftButton from '@/components/App/User/MicrosoftButton/MicrosoftButton.vue';
import GoogleButton from '@/components/App/User/GoogleButton/GoogleButton.vue';
import SingleSignOnButton from '@/components/App/User/SingleSignOnButton/SingleSignOnButton.vue';

export default {
  components: {
    MicrosoftButton,
    GoogleButton,
    SingleSignOnButton,
  },
  data() {
    return {
      email: '',
      password: '',
      validator: null,
      show_password: false,
    };
  },
  methods: {
    ...mapActions([LOGIN_REQUEST]),
    async submit() {
      const data = {
        currentRequest: 'login',
        requestData: {
          email: this.email,
          password: this.password,
        },
      };
      this.clearData();
      this.LOGIN_REQUEST(data);
    },
    clearData() {
      this.email = '';
      this.password = '';
      this.validator.reset();
    },
  },
  computed: {
    getFormErrors: function() {
      const errors = [];
      if (this.validator) {
        Object.entries(this.validator.errors).forEach(([key, value]) => {
          if (value.length > 0) {
            errors.push(...value);
          }
        });
      }
      return errors;
    },
    loginFormErrors: function() {
      let errMessage = '';
      const errors = this.getFormErrors;
      if (errors.length > 0) {
        errMessage =
          !this.email || !this.password ?
            'Please fix the errors' :
            'Email or password is incorrect';
      }
      return errMessage;
    },
  },
  mounted: function() {
    this.validator = this.$refs.formValidator;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/lbd/mixins/_layout.scss";
@import "@/assets/sass/custom/_app-vars.scss";

.login-logo {
  width: 150px;
}

.grid {
  display: grid;
  height: fit-content;
}

.self-end {
  justify-self: end;
}

.layout-button {
  @include respond-to(sm) {
    width: 100% !important;
  }
  @include respond-to(xs) {
    width: 100% !important;
  }
}

.eye-icon:hover {
  color: $primary-border-color;
}
</style>
