import { render, staticRenderFns } from "./SingleSignOnLogo.vue?vue&type=template&id=55aa2e38&scoped=true"
import script from "./SingleSignOnLogo.vue?vue&type=script&setup=true&lang=ts"
export * from "./SingleSignOnLogo.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SingleSignOnLogo.vue?vue&type=style&index=0&id=55aa2e38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55aa2e38",
  null
  
)

export default component.exports